var Init = function(){
  var $window = $(window);
  var $body = $("body");
  var $header = $("header");

  var mouse_event = {
    "start": _isSP ? "touchstart" : "mousedown",
    "move": _isSP ? "touchmove" : "mousemove",
    "end": _isSP ? "touchend" : "mouseup mouseleave"
  };

  var $mv = $(".mv");
  var NavColorChange = function(){
    if($window.scrollTop() > $mv.outerHeight() - $header.outerHeight()){
      if(!$header.hasClass("header--scrolled")){
        $header.addClass("header--scrolled");
      }
    }else{
      if($header.hasClass("header--scrolled")){
        $header.removeClass("header--scrolled");
      }
    }
  };
  $window.on("scroll", NavColorChange);
  NavColorChange();

  var AddZero = function(n){
    var _n = "";
    if(n < 10){
      _n = "0" + n.toString();
    }
    return _n;
  };


  var LoopInit = function(){
    var $mvContent = $(".mv-content");
    var $mvBoxes = $mvContent.find(".mv-box");
    var mvLength = $mvBoxes.length;
    var $mvIndicator = $(".mv-pagenation");
    var $mvIndicatorN = $mvIndicator.find(".numer");
    var $mvIndicatorD = $mvIndicator.find(".denom").text(AddZero(mvLength));
    var $mvIndicatorBar = $(".mv--bar span");
    var $arrows = $(".mv--arrow");
    var $prev = $(".mv--prev");
    var $next = $(".mv--next");
    var barTime = 80;
    var rotationTime = 8000;
    var transitions = ['_scale1', '_slide1', '_scale2', '_slide2'];

    var currentCount;
    var barTimer = 0;
    var changeTimer = 0;

    var VisualChange = function(count){
      console.log("VisualChange");
      currentCount = count;

      console.log(currentCount);

      if(currentCount < 0){
        currentCount = mvLength;
      }else{
        currentCount %= mvLength;
        currentCount++;
      }

      $mvIndicatorN.text(AddZero(currentCount));

      console.log(currentCount);

      $mvIndicatorBar.css({width: ""});

      var _transition = transitions[Math.floor(Math.random() * 4)];
      $mvBoxes.each(function(i){
        var _$this = $(this);
        _$this
          .removeClass("mv-box--open");
        if(i === currentCount - 1){
          _$this
            .removeClass(transitions.toString().replaceAll(",", " "))
            .delay(100)
            .queue(function(){
              _$this
                .addClass("mv-box--open " + _transition)
                .dequeue();
            });
        }
      });
    };

    var VisualLoopBar = function(barPercentage, barCount){
      console.log("VisualLoopBar");
      var _barPercentage = barPercentage !== void 0 ? barPercentage : 0;
      var _barCount = barCount !== void 0 ? barCount : 1;

      barTimer = setInterval(function(){
        _barCount++;
        _barPercentage = ((barTime * _barCount) / rotationTime) * 100;
        var _percent = -100 + _barPercentage + 1
        if(_percent > 100){
          _percent = 100;
        }
        //console.log(_percent);
        $mvIndicatorBar.css({"transform": "translateY(" + _percent + "%)"});
      }, barTime);
      return barTimer;
    };

    var StartVisualChange = function(){
      console.log("StartVisualChange");
      changeTimer = setTimeout(
        function(){
          //VisualChange(currentCount);
          clearInterval(barTimer);
          clearTimeout(changeTimer);
          VisualLoop(currentCount);
        },
        rotationTime
      );

      //console.log("StopVisualChange: " + changeTimer);

      return changeTimer;
    };

    var VisualLoop = function(count){
      console.log("-----VisualLoop-----");
      VisualChange(count);
      barTimer = VisualLoopBar();
      changeTimer = StartVisualChange();
    };
    VisualLoop(0);

    var StopVisualLoop = function(count){
      console.log("StopVisualLoop: " + changeTimer);
      clearInterval(barTimer);
      clearTimeout(changeTimer);
      VisualLoop(count);
    };

    var loopSwipeX = 0;
    var loopMoveX = 0;
    var loopSwipeY = 0;
    var loopMoveY = 0;
    var loopDist = 100;

    var LoopSwipeStart = function(event){
      console.log('LoopSwipeStart');
      if(!_isSP){
        event.preventDefault();
      }

      if(_isSP){
        loopSwipeX = event.originalEvent.touches[0].pageX;
        loopSwipeY = event.originalEvent.touches[0].pageY;
      }else{
        loopSwipeX = event.originalEvent.pageX;
      }

      $mvContent.on(mouse_event.move, LoopSwipeMove);
      $mvContent.on(mouse_event.end, LoopSwipeEnd);
    };

    var LoopSwipeMove = function(event){
      console.log('LoopSwipeMove');
      if(!_isSP){
        event.preventDefault();
      }
      var $target = $(event.currentTarget);

      var _moveX;

      if(_isSP){
        _moveX = Math.abs(loopSwipeX - event.originalEvent.changedTouches[0].pageX);
      }else{
        _moveX = Math.abs(loopSwipeX - event.originalEvent.pageX);
      }

      _moveX = (_moveX / 1000);
      if(_moveX >= 0.5){
        _moveX = 0.5;
      }
      $target.css({opacity: 1 - _moveX});
    };

    var LoopSwipeEnd = function(event){
      console.log('LoopSwipeEnd');
      if(!_isSP){
        event.preventDefault();
      }

      $mvContent.off(mouse_event.move, LoopSwipeMove);
      $mvContent.off(mouse_event.end, LoopSwipeEnd);

      var $target = $(event.currentTarget);
      $target.stop().animate({opacity: 1}, 300);

      if(_isSP){
        loopMoveX = event.originalEvent.changedTouches[0].pageX;
        loopMoveY = event.originalEvent.changedTouches[0].pageY;
      }else{
        loopMoveX = event.originalEvent.pageX;
      }

      if(loopSwipeX + loopDist < loopMoveX){
        console.log('prev');
        StopVisualLoop(currentCount - 2);
        return;
      }

      if(loopSwipeX > loopMoveX + loopDist ){
        console.log('next');
        StopVisualLoop(currentCount);
        return;
      }

      if(_isSP){
        if(Math.abs(loopSwipeY - loopMoveY) > 0){
          return;
        }
      }

      var _href = $target.find('.mv-box--open a').attr('href');
      var _isBlank = $target.find('.mv-box--open a').attr('target') === '_blank';

      //console.log($target.find('.mv-box--open a'));
      //console.log(_href);

      if(!_isBlank){
        $body.animate({opacity: 0}, 500, function(){
          $body.removeClass("nav--open").removeClass("is-modal")
          window.location.href = _href;
          $body.delay(1000).animate({opacity: 1});
        });
      }
    };

    var ArrowMouseOver = function(event){
      if(_isSP){
        $arrows.removeClass('hover');
      }else{
        var _class = $(event.currentTarget).attr('class');
        $arrows.each(function(){
          var _$this = $(this);
          if(!_$this.hasClass(_class)){
            _$this.addClass('hover');
          }
        });
      }
    };

    var ArrowMouseOut = function(event){
      $arrows.removeClass('hover');
    };

    var ArrowMouseStart = function(event){
      var _$this = $(event.currentTarget);
      _$this.removeClass('end').addClass('start');
    };

    var ArrowMouseEnd = function(event){
      var _$this = $(event.currentTarget);
      _$this.addClass('end').removeClass('start').delay(500).queue(function(){{
        _$this.removeClass('end').dequeue();
      }});
    };

    $arrows.on('mouseover', ArrowMouseOver);
    $arrows.on('mouseleave', ArrowMouseOut);
    $arrows.on(mouse_event.start, ArrowMouseStart);
    $arrows.on(mouse_event.end, ArrowMouseEnd);

    $prev.on('click', function(){
      console.log('prev');
      StopVisualLoop(currentCount - 2);
    });
    $next.on('click', function(){
      console.log('next');
      StopVisualLoop(currentCount);
    });

    var Clicked = function(event){
      return false;
    };

    $mvContent.on(mouse_event.start, LoopSwipeStart);
    $mvContent.find('a').on('click', Clicked);

    Resize();
  };

  var Resize = function(){
    //console.log("Resize");
    $(".mv").css({height: $window.innerHeight()});
    //$(".mv-box").css({height: $window.innerHeight()});
    if($window.width() > 767){
    }else{
    }

    mouse_event = {
      "start": _isSP ? "touchstart" : "mousedown",
      "move": _isSP ? "touchmove" : "mousemove",
      "end": _isSP ? "touchend" : "mouseup mouseleave"
    };
  };
  $window.on("resize", Resize);


  var _mv = new JSONReader("#mv", "mv");
  _mv.read("../mt_output/json/top-prom.json", LoopInit);

  var _info = new JSONReader("#mv_bottom .inner", "mv_info");
  _info.read("../mt_output/json/top-info.json", 1);

  var _readings = new JSONReader(".readings-contents", "readings");
  _readings.read("../mt_output/json/readings-list.json", 6);

  var _news = new JSONReader(".news-contents", "news");
  _news.read("../mt_output/json/news-list.json", 5);
};

window.addEventListener("load", Init);